<template>
  <div>
    <v-layout fill-height align-center justify-space-between>
      <v-text-field
        v-if="editUrl && (!item.ReadOnly||editItemAdmin)"
        :label="$t('Website URL')"
        class="mr-8"
        v-model="item.Value"
        :rules="$validation.getValidation('notEmptyOrWrongURLRule')"
      ></v-text-field>
      <a v-else-if="item.Value" :href="item.Value" target="_blank">
        <v-layout fill-height align-center justify-start>
          <v-img
            v-if="!$store.getters.editMode"
            contain
            max-height="16"
            max-width="16"
            :src="getFavicon(item)"
            class="mr-2"
          >
          </v-img>
          <p class="mb-0 text-decoration-underline">
            {{ getDomainUrl(item.Value) }}
          </p>
        </v-layout>
      </a>
      <p v-else class="mb-0">
        {{ $t("No URL added yet") }}
      </p>
      <v-switch
        v-if="(!item.ReadOnly && !needsDisable)||editItemAdmin"
        v-model="editUrl"
        :label="$t('Edit')"
      ></v-switch>
    </v-layout>
    <v-row no-gutters v-if="$store.getters.editMode" class="mt-4">
      <v-col cols="12">
        <v-text-field
          v-model.number="item.colWidth"
          hide-details="auto"
          type="number"
          max="12"
          min="1"
          dense
          :label="$t('Column width')"
          class="mt-4 mw-w"
        ></v-text-field>
        <v-switch
          v-model="item.ReadOnly"
          class="float-left mr-3"
          hide-details="auto"
          :label="$t('Read-only')"
        ></v-switch>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: [Array, Object],
    },
    needsDisable: {
      type: Boolean,
    },
    editItemAdmin: {
      type: Boolean,
    }
  },
  data() {
    return {
      editUrl: false,
    };
  },
  methods: {
    getFavicon(item) {
      return this.$helpers.getFaviconFromGoogle(item);
    },
    getDomainUrl(item) {
      return this.$helpers.getDomainUrl(item);
    },
  },
};
</script>

<style scoped>
.mw-w {
  min-width: 2rem;
  max-width: 8rem;
}
</style>